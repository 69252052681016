import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { UbnSelectorDropdown } from "./UbnSelectorDropdown";
import { capitalizeFirst } from "../utils/helpers";
import ApiStorage from "../../ApiStorage";
import APIService from "api/APIService";
import { useDispatch } from "react-redux";
import { loginActions } from "api/APIService";

export default function TopBreadcrumbs() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [organization, setOrganization] = useState("");
  const [selectedUbn, setSelectedUbn] = useState(null);
  const [allUbns, setAllUbns] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = await ApiStorage.getSessionToken();
        const tokenJson = JSON.parse(token);
        const organizationName = getOrganizationName(tokenJson);
        const ubnName = getSelectedUbn(tokenJson);

        setOrganization(organizationName);
        setSelectedUbn(ubnName);
        dispatch(loginActions.selectedUbn(ubnName));
        setAllUbns(getAllUbns(tokenJson));
      } catch (e) {
        console.error(e, "<--- error getting session token");
      }
    };

    getUser();
  }, [dispatch]);

  const getEbsi = async (ubn) => {
    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    try {
      const resp = await APIService.getUbnEbsi({
        paymail: ubn.paymail,
      });

      userJson.ebsi = resp;
      await ApiStorage.setSessionToken(JSON.stringify(userJson));
    } catch (e) {
      console.error(e.message);
      userJson.ebsi = {};
      await ApiStorage.setSessionToken(JSON.stringify(userJson));
    }
  };

  const pressedUbn = async (ubn) => {
    const user = await ApiStorage.getSessionToken();
    const userJson = JSON.parse(user);
    userJson.backend = ubn;
    await ApiStorage.setSessionToken(JSON.stringify(userJson));
    dispatch(loginActions.selectedUbn(ubn));
    setSelectedUbn(ubn);
    if (
      APIService.isCapable(userJson, APIService.capabilities.ebsiTrackTrace)
    ) {
      getEbsi(ubn);
    }

    history.push("/");
  };

  return (
    <div className={classes.container}>
      {organization && (
        <Typography color="secondary" variant="h5">
          {APIService.companyName === "SeafoodChain"
            ? "SeafoodChain"
            : capitalizeFirst(organization.split(" ")[0])}
        </Typography>
      )}
      {organization && selectedUbn && (
        <ChevronRightIcon className={classes.icon} />
      )}
      {selectedUbn && (
        <UbnSelectorDropdown
          selectedUbn={selectedUbn}
          allUbns={allUbns}
          pressedUbn={pressedUbn}
        />
      )}
    </div>
  );
}

const getOrganizationName = (token) => {
  let organizationName = "";
  const o = token.userProfile?.organizations;
  if (o && o.length > 0) {
    organizationName = o[0].name;
  }
  return organizationName;
};

const getSelectedUbn = (token) => {
  let selectedUbn;
  const b = token.backend?.backendUri;
  const e = token.userProfile?.endpoints;
  if (b && e && e.length > 0) {
    selectedUbn = e.find((u) => u.backendUri === b);
  }
  return selectedUbn;
};

const getAllUbns = (token) => {
  if (token.userProfile?.endpoints) {
    return token.userProfile.endpoints.filter((e) => e.service === "UBN");
  }
  return [];
};

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    display: "flex",
  },
  icon: {
    margin: "0 0.5rem",
  },
  ubnText: {
    cursor: "pointer",
  },
}));
